import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "50",
  height: "50",
  viewBox: "0 0 50 50",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { id: "team" }, [
      _createElementVNode("path", {
        id: "Vector",
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M12.8865 19.8856C12.8865 22.4523 10.8058 24.533 8.23915 24.533C5.67249 24.533 3.5918 22.4523 3.5918 19.8856C3.5918 17.319 5.67249 15.2383 8.23915 15.2383C10.8058 15.2383 12.8865 17.319 12.8865 19.8856ZM0 28.2236H9.87563V41.2739H0V28.2236ZM43.2743 24.533C45.841 24.533 47.9217 22.4523 47.9217 19.8856C47.9217 17.319 45.841 15.2383 43.2743 15.2383C40.7076 15.2383 38.627 17.319 38.627 19.8856C38.627 22.4523 40.7076 24.533 43.2743 24.533ZM40.3457 28.7305H50.0004V41.2734H40.3457V28.7305Z",
        fill: "#212529"
      }),
      _createElementVNode("path", {
        id: "Vector_2",
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M31.6964 15.2476C31.6964 18.8491 28.7768 21.7686 25.1753 21.7686C21.5739 21.7686 18.6543 18.8491 18.6543 15.2476C18.6543 11.6461 21.5739 8.72656 25.1753 8.72656C28.7768 8.72656 31.6964 11.6461 31.6964 15.2476ZM36.9819 25.1396H13.377V41.2663H36.9819V25.1396ZM34.8218 31.9144H28.579V34.508H34.8218V31.9144Z",
        fill: "#F06F02"
      })
    ], -1)
  ])))
}
export default { render: render }